<template>
  <div id="buy">
    <article id="wrap">
      <AppHeader theme="white">わたしのこと</AppHeader>

      <section class="pb_150" v-if="order">
        <table class="table02">
          <tbody><tr>
            <th><img src="@/assets/img/mypage/icon_info.png" alt="インフォメーションアイコン">注文情報</th>
            <td>
              <ul class="flex between p_15 underline_green">
                <li>受注日</li>
                <li>{{ order.created_at | formatDate }}</li>
              </ul>
              <ul class="flex between p_15 underline_green">
                <li>売上ID</li>
                <li>{{ ( '0000000000' + order.id ).slice( -10 ) }}</li>
              </ul>
              <ul class="flex between p_15 underline_green">
                <li>注文者名</li>
                <li>{{ order.last_name }} {{ order.first_name }} ({{ order.last_kana }} {{ order.first_kana }})</li>
              </ul>
              <ul class="flex between p_15">
                <li>売上金額</li>
                <li>{{ order.price | currency }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th><img src="@/assets/img/mypage/icon_info.png" alt="インフォメーションアイコン">注文処理</th>
            <td>
              <ul class="flex between p_15 underline_green">
                <li>発送状態</li>
                <li class="order_states_off" v-if="!isSend">未発送</li>
                <li class="order_states_on" v-if="isSend">発送</li>
              </ul>
              <ul class="flex between p_15 underline_green" v-if="isSend">
                <li>配送変更日</li>
                <li>{{ order.send_at | formatDate }}</li>
              </ul>
              <ul class="flex between p_15 underline_green" v-if="isSend">
                <li>メッセージ</li>
                <li>{{ order.message }}</li>
              </ul>
              <div class="p_15" v-if="!isSend">
                <div class="mb_15 ta_c">商品を発送後、発送メールを送信しましょう</div>
                <div class="bt_form mb_15">
                  <RouterLink :to="{name: 'order-edit', params: {id: $route.params.id}}">発送済に変更する</RouterLink>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th><img src="@/assets/img/mypage/icon_info.png" alt="インフォメーションアイコン">配送先情報</th>
            <td>
              <div class="p_15 underline_green">
                <div class="mb_15">{{ order.last_name }} {{ order.first_name }} ({{ order.last_kana }} {{ order.first_kana }})</div>
                <div class="">〒{{ order.zip }}<br>
                  {{ order.prefecture }}{{ order.city }}{{ order.street }}
                </div>
              </div>
              <ul class="flex between p_15">
                <li>電話番号</li>
                <li>{{ order.tel }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th><img src="@/assets/img/mypage/icon_info.png" alt="インフォメーションアイコン">発送する商品</th>
            <td>
              <div class="item" :class="{underline_green: index + 1 < order.carts.length}" v-for="(cart, index) in order.carts" :key="cart.id">
                <ul class="col2 p_15">
                  <li class="col2L">
                    <div class="item_photo">
                      <img :src="cart.item.item_photos[0].image | imageUrl" alt="商品画像" v-if="cart.item.item_photos.length">
                      <img :src="'' | imageUrl" alt="商品画像" v-else>
                    </div>
                  </li>
                  <li class="col2R flex_g1">
                    <div class="item_title">{{ cart.item.name }}</div>
                    <div class="order_volume mt_15">在庫：{{ cart.value }}</div>
                  </li>
                </ul><!--.col2-->
              </div><!-- .item -->

<!--              <div class="item">-->
<!--                <ul class="col2 p_15">-->
<!--                  <li class="col2L">-->
<!--                    <div class="item_photo"><img src="@/assets/img/mypage/item_photo.jpg" alt="商品画像"></div>-->
<!--                  </li>-->
<!--                  <li class="col2R flex_g1">-->
<!--                    <div class="item_title">まままままままままままままままままままままままま</div>-->
<!--                    <div class="order_volume mt_15">在庫：2</div>-->
<!--                  </li>-->
<!--                </ul>&lt;!&ndash;.col2&ndash;&gt;-->
<!--              </div>&lt;!&ndash; .item &ndash;&gt;-->
            </td>
          </tr>
          </tbody></table>

      </section>

      <AppFooter theme="yellow" fixed></AppFooter>
    </article>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import moment from 'moment';
export default {
  components: { AppHeader, AppFooter },
  data () {
    return {
      order: null
    }
  },
  computed: {
    isArtist () {
      return !!this.$store.state.loginUser.artist_id;
    },
    isSend() {
      return !!this.order.send_at;
    }
  },
  filters: {
    formatDate (val) {
      moment.locale("ja");
      return moment(val).format('YYYY年M月D日(dd) HH:mm');
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const response = await this.$http.get(`orders/${this.$route.params.id}`);
      this.order = response.data;
    }
  }
}
</script>
